import React, { useRef, useState } from 'react'

import { SnackbarItem } from '@juullabs/juulio-bridge/apps/notification/components/Snackbar/SnackbarItem'
import classNames from 'classnames'
import pick from 'lodash/fp/pick'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { useUiStore } from 'stores'
import { removeSnackbarItem, SnackbarPosition } from 'stores/uiStore'
import type { SnackbarItemType } from 'stores/uiStore'
import { useMediaQuery } from 'utils/complianceUI'

import itemAddedToCartStyles from './ItemAddedToCart.module.scss'
import itemAddedToCartWidescreenStyles from './ItemAddedToCartWidescreen.module.scss'
import itemBottomStyles from './ItemBottom.module.scss'
import itemTopStyles from './ItemTop.module.scss'
import styles from './Snackbar.module.scss'

const SNACKBAR_ITEM_TRANSITION_TIMEOUT = 500

type Props = {
  defaultPosition?: SnackbarPosition
}

type SnackbarItemsProps = {
  itemsForDrawer: SnackbarItemType[]
  position: SnackbarPosition
  theme: 'light' | 'dark'
}

const SnackbarItems =
  ({ itemsForDrawer, position, theme }: SnackbarItemsProps) => {
    const childNodeRef = useRef<HTMLDivElement>()
    const isWidescreen = useMediaQuery('(min-width: 768px)')
    const [animClassName, setAnimClassName] = useState(undefined)

    const positionStyles = {
      [SnackbarPosition.AddedToCart]: isWidescreen
        ? itemAddedToCartWidescreenStyles
        : itemAddedToCartStyles,
      [SnackbarPosition.Bottom]: itemBottomStyles,
      [SnackbarPosition.Top]: itemTopStyles,
    }[position]

    const snackbarItemClasses =
    (animationClassName: string): string =>
      classNames(
        positionStyles.base,
        animationClassName,
        {
          [styles.cartSnackbarWrapperInverted]: theme === 'dark',
        },
      )

    return (
      <TransitionGroup component={React.Fragment}>
        {itemsForDrawer.map(item => (
          <CSSTransition
            classNames={{
              ...pick(['enter', 'enterActive', 'exit', 'exitActive'], positionStyles),
            }}
            nodeRef={childNodeRef}
            key={item.id}
            onEnter={() => setAnimClassName(positionStyles.enter)}
            onEntered={() => setAnimClassName(positionStyles.enterActive)}
            onExit={() => setAnimClassName(positionStyles.exit)}
            onExited={() => setAnimClassName(positionStyles.exitActive)}
            timeout={SNACKBAR_ITEM_TRANSITION_TIMEOUT}
          >
            <SnackbarItem
              ref={childNodeRef}
              className={snackbarItemClasses(animClassName)}
              duration={item.duration}
              onExit={() => {
                item.onExit?.()
                removeSnackbarItem(item.id)
              }}
              updateAnimationClassName={styles.snackbarItemShake}
            >
              {item.children}
            </SnackbarItem>
          </CSSTransition>
        ))}
      </TransitionGroup>
    )
  }

export const Snackbar: React.FC<Props> = ({ defaultPosition }) => {
  const {
    snackbar: {
      items,
      theme,
    },
  } = useUiStore()

  return (
    <div
      role='status'
      aria-live='polite'
      aria-relevant='additions'
    >
      {Object.values(SnackbarPosition).map(position => {
        const itemsForDrawer = items.filter(
          item =>
            (position === defaultPosition && !item.position) ||
            item.position === position,
        )

        const snackbarClasses = classNames(
          styles.snackbarDrawer,
          {
            [styles.snackbarDrawerAddedToCart]:
              position === SnackbarPosition.AddedToCart,
            [styles.snackbarDrawerBottom]: position === SnackbarPosition.Bottom,
            [styles.snackbarDrawerTop]: position === SnackbarPosition.Top,
            'content-wrapper': position === SnackbarPosition.Bottom,
          },
        )

        return (
          <div
            key={position}
            className={snackbarClasses}
          >
            <SnackbarItems
              itemsForDrawer={itemsForDrawer}
              position={position}
              theme={theme}
            />
          </div>
        )
      })}
    </div>
  )
}
